import React from 'react';
import { css } from '@emotion/css';
import { Callout, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { labelOffsetFromTopOfAreaBeingLabeled } from './constant';
import { CalloutSkins } from '../../../../config/constant';
import { Tooltip } from '../Tooltip';

const toolTipStyle = css`
    color: ${tokens.SwanSemColorTextStandard};
    font-size: ${tokens.SwanSemFontSizeXsmall};
    background: ${tokens.SwanSemColorBgContainer};
    border: ${tokens.SwanSemBorderWidthFloating} solid ${tokens.SwanSemColorBorderContainer}
    border-radius: ${tokens.SwanSemBorderRadiusContainer};
`;

const labelContainerStyle = css`
    &:not(:first-of-type) {
        margin-left: ${tokens.SwanSemSpace4};
    }
    display: block;
    .quad-tooltip__trigger {
        display: block;
    }
`;

const labelStyle = css`
    pointer-events: auto;
`;

const indicatorLineBackground = css`
    position: absolute;
    width: 20px;
    border-left: 1px solid ${tokens.SwanSemColorBorderNone};
    opacity: 0.6;
`;

const indicatorLine = css`
    position: absolute;
    width: 20px;
    border-left: 2px dashed;
`;

const indicatorDot = css`
    border: 1px solid;
    position: absolute;
    border-radius: ${tokens.SwanSemBorderRadiusSubtle};
    height: 8px;
    width: 8px;
`;

export interface DesignAreaLabelProps {
    /**
     * The label for the area
     */
    label: string;
    /**
     * The callout skin
     */
    calloutSkin: CalloutSkins;
    /**
     * The tooltip text for the area
     */
    tooltip?: string;
    /**
     * How many pixels the area label should be offset in the Y direction from the top of the IDA,
     * used to put labels next to the area that they're labeling on the IDA, rather than floating them at the top
     */
    offsetYPixels?: number;
    /**
     * How far across the IDA (in pixels) the label should be offset.
     */
    offsetXPixels?: number;
    /**
     * Whether to show a line beneath the label pointing to the area that it should be labeling.  Used in conjunction with offsetYPixels
     * and offsetXPercentage to let us float a label over the IDA and point it at the area that it is labeling.
     */
    showIndicatorLine?: boolean;
    /**
     * The color associated with the area on the IDA, used to color the line and dot which point from the label to the area on the IDA
     * that is being labeled
     */
    maskColor?: string;

    /**
     * How many pixels tall the line pointing to the label should be - Defaults to 15px
     */
    lineIndicatorHeight?: number;
}

const getAdditionalOffSetStyle = (offsetXPixels: number, offsetYPixels?: number) =>
    offsetXPixels
        ? ({
              position: 'absolute',
              transform: 'translate(-50%, -25%)',
              left: `${offsetXPixels}px`,
              top: `${offsetYPixels}px`,
          } as React.CSSProperties)
        : {};

export const DesignAreaLabel = observer(
    ({
        label,
        maskColor,
        calloutSkin,
        tooltip,
        offsetXPixels = 0,
        offsetYPixels,
        showIndicatorLine,
        lineIndicatorHeight = 15,
    }: DesignAreaLabelProps) => {
        const labelContent = (
            <Callout className={labelStyle} skin={calloutSkin} fontSize="xsmall">
                {label}
            </Callout>
        );

        const additionalStyles = getAdditionalOffSetStyle(offsetXPixels, offsetYPixels);
        const indicatorLineTopOffset = (offsetYPixels ?? 0) + labelOffsetFromTopOfAreaBeingLabeled;
        /* This magic 3.5px comes from the fact that dot element is 8px wide *
         * To show the dot exact below the line indicator on the canvas from left
         */
        const indicatorDotLeftAdjust = 3.5;
        /* The above magic 14 is coming from the labelContainerStyle which has a height of 18px, minus 4px (half of dot height)
         * To show the dot exact on the mask line on the canvas from top
         */
        const indicatorDotTopAdjust = 14;
        const indicatorDotLeftOffset = offsetXPixels - indicatorDotLeftAdjust;
        const indicatorDotTopOffset =
            (offsetYPixels ?? 0) + labelOffsetFromTopOfAreaBeingLabeled + lineIndicatorHeight + indicatorDotTopAdjust;

        return (
            <>
                <div className={labelContainerStyle} style={additionalStyles}>
                    {tooltip && (
                        <Tooltip
                            content={tooltip}
                            placement="top"
                            triggerAriaLabel={label}
                            trigger={['hover', 'focus']}
                            mouseLeaveDelay={500}
                            tooltipContainerClassName={toolTipStyle}
                        >
                            {labelContent}
                        </Tooltip>
                    )}
                    {!tooltip && labelContent}
                </div>
                {showIndicatorLine && (
                    <>
                        <div
                            className={indicatorLineBackground}
                            style={{
                                left: `${offsetXPixels}px`,
                                top: `${indicatorLineTopOffset}px`,
                                height: `${labelOffsetFromTopOfAreaBeingLabeled + lineIndicatorHeight}px`,
                            }}
                        />
                        <div
                            className={indicatorLine}
                            style={{
                                left: `${offsetXPixels}px`,
                                top: `${indicatorLineTopOffset}px`,
                                height: `${labelOffsetFromTopOfAreaBeingLabeled + lineIndicatorHeight}px`,
                                borderLeftColor: maskColor,
                            }}
                        />
                        <div
                            className={indicatorDot}
                            style={{
                                left: `${indicatorDotLeftOffset}px`,
                                top: `${indicatorDotTopOffset}px`,
                                backgroundColor: maskColor,
                                borderColor: maskColor,
                            }}
                        />
                    </>
                )}
            </>
        );
    }
);
