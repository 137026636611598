import React, { useMemo } from 'react';
import { cm2mm } from '@design-stack-ct/utility-core';
import type { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { PanelLayoutExtension, useDesignEngine } from '@design-stack-vista/core-features';
import { DesignLayer, MaskType } from '@design-stack-vista/ida-framework';
import { css } from '@emotion/css';
import { tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { labelOffsetFromTopOfAreaBeingLabeled, maskOffsetXPercentage, messages } from './constant';
import { DesignAreaLabel, DesignAreaLabelProps } from './DesignAreaLabel';
import { DesktopExperience } from '../../../../components/Experiences/DesktopExperience';
import { DESIGN_MASK_CONFIGURATION } from '../../../../config/constant';
import { useProductConfiguration } from '../../../../hooks/calcifer';
import { text } from '../../../../utils/localization';
import { PanelChromesExtension } from '../../Extensions';

export interface PresentationalDesignMarginsProps {
    panel: PanelState;
    onLabelHover: (maskType: MaskType | null) => void;
}

const labelContainerStyle = css`
    position: absolute;
    left: 0;
    top: -33px;
    bottom: auto;
    height: 18px;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    width: 100%;

    > div {
        margin-left: ${tokens.SwanSemSpace4};
    }
`;

type ChromeLabel = DesignAreaLabelProps & { maskType: MaskType };

function MapMaskOverlays({ panel, onLabelHover }: PresentationalDesignMarginsProps): JSX.Element | null {
    const { productData } = useProductConfiguration();
    const { designExtensionSystem, layoutStore } = useDesignEngine();

    if (!productData) {
        return null;
    }

    const panelChromes = designExtensionSystem.getExtension(panel.iid, PanelChromesExtension);
    const panelLayout = designExtensionSystem.getExtension(panel.iid, PanelLayoutExtension);
    const masks = panelChromes?.masks;
    const totalWidth = (panelLayout?.dimensions.width ?? 0) / layoutStore.zoom;

    const isMaskTypeAlreadyExists = (arr: ChromeLabel[], maskType: string) =>
        arr.some((element) => element.maskType === maskType);

    const chromeLabels: ChromeLabel[] = useMemo(
        () =>
            masks
                ? masks.reduce((acc: ChromeLabel[], { type, boundingArea }) => {
                      if (
                          messages[`${type.toLowerCase()}Label`] &&
                          DESIGN_MASK_CONFIGURATION[type].enabled &&
                          !isMaskTypeAlreadyExists(acc, type)
                      ) {
                          const offsetXPercentage = maskOffsetXPercentage[type];
                          const xPositionInSvgSpaceCoordinates = (offsetXPercentage / 100) * totalWidth;
                          const offsetXPixels = xPositionInSvgSpaceCoordinates * layoutStore.zoom;
                          const offsetYPixels =
                              cm2mm(boundingArea?.position?.y ?? 0) * layoutStore.zoom -
                              labelOffsetFromTopOfAreaBeingLabeled;
                          acc.push({
                              maskType: type,
                              label: messages[`${type.toLowerCase()}Label`],
                              tooltip: messages[`${type.toLowerCase()}Tooltip`],
                              maskColor: DESIGN_MASK_CONFIGURATION[type].backgroundColor,
                              calloutSkin: DESIGN_MASK_CONFIGURATION[type].calloutSkin,
                              offsetYPixels,
                              offsetXPixels,
                          });
                      }
                      return acc;
                  }, [])
                : [],
        [masks, layoutStore.zoom]
    );

    return (
        <div className={labelContainerStyle}>
            {chromeLabels.map(
                ({ label, tooltip, calloutSkin, maskType, offsetXPixels, offsetYPixels, maskColor }: ChromeLabel) => (
                    <div
                        key={text(label)}
                        onMouseOver={() => onLabelHover(maskType)}
                        onMouseOut={() => onLabelHover(null)}
                        onFocus={() => onLabelHover(maskType)}
                        onBlur={() => onLabelHover(null)}
                    >
                        <DesignAreaLabel
                            label={text(label)}
                            calloutSkin={calloutSkin}
                            tooltip={tooltip && text(tooltip)}
                            showIndicatorLine={true}
                            offsetXPixels={offsetXPixels}
                            offsetYPixels={offsetYPixels}
                            maskColor={maskColor}
                        />
                    </div>
                )
            )}
        </div>
    );
}

export const LegendLayer = observer(({ panel, onLabelHover }: PresentationalDesignMarginsProps) => (
    <DesktopExperience>
        <DesignLayer name="panel-masks-info">{MapMaskOverlays({ panel, onLabelHover })}</DesignLayer>
    </DesktopExperience>
));
