import { action, makeAutoObservable, observable } from 'mobx';

class RecentImageStore {
    @observable isPreviewFailed = false;
    @observable isPreviewLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    @action.bound
    setIsPreviewFailed(bool: boolean) {
        this.isPreviewFailed = bool;
    }

    @action.bound
    setIsPreviewLoading(bool: boolean) {
        this.isPreviewLoading = bool;
    }
}

export const recentImageStore = new RecentImageStore();
