import { parseAssetUrl } from '@design-stack-ct/assets-sdk';
import {
    calculateCoverPosition,
    getImageAsPromise,
    Measurement,
    MeasurementUnit,
    UnitlessDimensions,
} from '@design-stack-ct/utility-core';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import {
    FileTypeNotSupportedError,
    MaxFileSizeExceededError,
    UploadFailedError,
} from '@design-stack-vista/upload-components';
import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { text } from './localization';
import { addPageAction, noticeError } from './newRelicUtils';
import { getAllItemsInCimdocPanels } from './panelUtils';
import {
    checkIsAssetMultiPage,
    checkIsAssetWithImageDimensionInfo,
    checkIsImageWithDimensionMetadata,
} from './validateAssets';
import { getImageInfo, getPdfInfo } from '../api/sherbert';

export type ImageUrls = {
    thumb: string;
    preview: string;
    print: string;
    original: string;
};

export interface ImagePosition {
    x: string;
    y: string;
    width: string;
    height: string;
}

export interface ImageAttributes {
    previewUrl: string;
    printUrl: string;
    originalSourceUrl: string;
    pageNumber: number;
    position: ImagePosition;
}

export interface BuildImageAttributes {
    (
        panelUnitLessDimensions: UnitlessDimensions,
        imageUnitLessDimensions: UnitlessDimensions,
        imageUrls: ImageUrls,
        pageNum?: number
    ): ImageAttributes;
}

export type UsedAssets = {
    assetId: string;
    pages: number[];
};

export type AssetMetaData = {
    uniqueAssetId: string | undefined;
    fileType: string | undefined;
    size: number | undefined;
    timeToUploadAndProcess: number;
};

export enum MimeType {
    JPEG = 'image/jpeg',
    PDF = 'application/pdf',
    JSON = 'application/json',
}
export interface UploadError {
    errorMessage: string;
    validationMessage: string;
    title?: string;
}

export interface LocalImage {
    imageUrls: ImageUrls;
    imageUnitLessDimensions: UnitlessDimensions;
}

const DEFAULT_PAGE_NUMBER = 1;

const FUSION_COMPATIBLE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/bmp'];

export const getUnzoomedPanelDimensions = (panel: PanelState): UnitlessDimensions => {
    return {
        width: new Measurement(panel.panelProperties.width).mm,
        height: new Measurement(panel.panelProperties.height).mm,
    };
};

export const getMimeType = (asset: VistaAsset): string | undefined => {
    return asset.data?.info.storage?.contentType;
};

export const getAssetId = (asset: VistaAsset): string | undefined => {
    return asset.data?.id;
};

export const getAssetSizeInBytes = (asset: VistaAsset): number | undefined => {
    return asset.data?.info.storage?.fileSizeBytes;
};

export const getAssetUploadAndProcessTime = (asset: VistaAsset): number => {
    const { data } = asset;

    if (data && 'totalUploadTimeMs' in data) {
        return Number(data['totalUploadTimeMs']);
    }

    return 0;
};

export const extractAssetMetaData = (asset: VistaAsset): AssetMetaData => {
    return {
        uniqueAssetId: getAssetId(asset),
        fileType: getMimeType(asset),
        size: getAssetSizeInBytes(asset),
        timeToUploadAndProcess: getAssetUploadAndProcessTime(asset),
    };
};

export const getMediaAndFileType = (asset: VistaAsset): string[] => {
    const mimeType = getMimeType(asset);
    return mimeType ? mimeType.split('/') || [] : [];
};

export const getMediaAndFileTypesForMultipleUpload = (assets: (void | VistaAsset)[]) => {
    return assets.reduce(
        (acc: Record<string, string>, current: void | VistaAsset) => {
            if (current) {
                const [media, file] = getMediaAndFileType(current);
                acc.media += `${media};` || '';
                acc.files += `${file};` || '';
            }
            return acc;
        },
        { media: '', files: '' }
    );
};

export const getImageItemFromPanel = (panel: PanelState) => {
    return panel.items.find((item) => item.isImageItem());
};

export const getItemFromPanel = (panel: PanelState) => {
    return panel.items.find((item) => item.isImageItem() || item.isShapeItem());
};

type VistaAssetWithMultiPageInfo = VistaAsset & { data: { info: { image: { numPages: number } } } };
export function isAssetMultiPage(asset: VistaAsset): asset is VistaAssetWithMultiPageInfo {
    if (asset.data && asset.data.info.image) {
        return 'numPages' in asset.data.info.image;
    }
    return false;
}

export const getNumPages = (asset: VistaAsset) => {
    return isAssetMultiPage(asset) ? asset.data?.info.image.numPages ?? 1 : 1;
};

export const getUploadErrorMessage = (error: Error, fileTypes: string) => {
    const uploadError: UploadError = {
        errorMessage: text('uploadErrorMessage'),
        validationMessage: text('uploadErrorMessage'),
    };

    if (error instanceof UploadFailedError) {
        uploadError.errorMessage = text('fileUploadError');
    }

    if (error instanceof FileTypeNotSupportedError) {
        uploadError.title = text('unSupportedFileTypeErrorTitle');
        uploadError.errorMessage = text('supportedFilesTypes', { fileTypes });
        uploadError.validationMessage = `${uploadError.title} ${uploadError.errorMessage}`;
    }

    if (error instanceof MaxFileSizeExceededError) {
        uploadError.errorMessage = text('fileUploadSizeExceeded');
    }

    return uploadError;
};

export const logUploadError = (error: Error, productSurfaceId?: string) => {
    if (error instanceof UploadFailedError) {
        // adding page action only when something has gone wrong trying to upload an asset
        addPageAction('upload-failed', {
            error,
            productSurfaceId,
        });
    }
    noticeError(error, {
        method: 'onUploadError',
    });
};

export const logInstantUploadError = (error: Error | unknown, asset: VistaAsset) => {
    let assetUrl = '';
    try {
        assetUrl = asset.getUrl({ showDeleted: true });
    } catch {
        /* empty */
    }

    addPageAction('instant-upload-failed', {
        error,
        errorMessage: error instanceof Error ? error.message : '',
        fileType: getMimeType(asset),
        isDamAsset: asset.isDamAsset(),
        fileName: asset.data?.info?.storage?.fileName,
        assetId: asset.data?.id,
        assetName: asset?.data?.name,
        status: asset?.status?.type,
        rawError: 'rawError' in asset.status ? asset?.status?.rawError.message : '',
        assetError: asset.data?.info?.image && 'error' in asset.data.info.image ? asset?.data?.info?.image?.error : '',
        assetUrl,
    });
};

export const regexToExtractAssets = new RegExp(
    /.*assets\/(?<assetid>(?:[\d\w-])+~\d{3})\/webPreview(?:\/(?<pagenumber>\d+))?/m
);

export const getUsedAssets = (panels: PanelState[]) => {
    const uploadAssets: UsedAssets[] = [];

    const items = getAllItemsInCimdocPanels(panels);

    items.forEach((image) => {
        if (image.isImageItem()) {
            const {
                model: { previewUrl },
            } = image;

            if (previewUrl) {
                const result = regexToExtractAssets.exec(previewUrl);
                if (result !== null) {
                    const assetPageNumber = Number(result[2] || 1);
                    uploadAssets.push({
                        assetId: result[1],
                        pages: [assetPageNumber],
                    });
                    regexToExtractAssets.lastIndex = 0;
                }
            }
        }
    });

    return uploadAssets;
};

export const generateImageUrls = async (asset: VistaAsset, pageNum = 1): Promise<ImageUrls> => {
    const assetPreparationPromise = asset.prepare();
    const webPreviewPreparationPromise = asset.webPreview.prepare();
    const printPreparationPromise = asset.print.prepare();
    const thumbnailPreparationPromise = asset.thumbnail.prepare();

    await Promise.all([
        assetPreparationPromise,
        webPreviewPreparationPromise,
        printPreparationPromise,
        thumbnailPreparationPromise,
    ]);

    return {
        thumb: asset.thumbnail.getUrl({ includeSignature: true, pageNum }),
        preview: asset.webPreview.getUrl({ includeSignature: true, pageNum }),
        print: asset.print.getUrl({ includeSignature: true }),
        original: asset.getUrl({ includeSignature: true }),
    };
};

export const getUnitLessImageDimensions = async (url: string): Promise<UnitlessDimensions> => {
    const dimensions = await getImageAsPromise(url);
    const { width, height } = dimensions;

    return {
        width,
        height,
    };
};

export const getUnitLessSherbertImageDimensions = async (
    asset: VistaAsset,
    pageNo = 1
): Promise<UnitlessDimensions> => {
    let dimensions: UnitlessDimensions = { width: 0, height: 0 };

    if (checkIsAssetMultiPage(asset)) {
        const pageInfo = await asset.getPageImageInfo({ pageNo });

        if (!checkIsImageWithDimensionMetadata(pageInfo)) {
            throw new Error('Cannot retrieve page dimensions');
        }

        const { width, height } = pageInfo;
        dimensions = {
            width,
            height,
        };
    } else if (checkIsAssetWithImageDimensionInfo(asset)) {
        const { width, height } = asset.data.info.image;
        dimensions = {
            width,
            height,
        };
    }

    return dimensions;
};

export const buildImageAttributes: BuildImageAttributes = (
    panelUnitlessDimension,
    imageUnitLessDimensions,
    imageUrls,
    pageNumber = DEFAULT_PAGE_NUMBER
) => {
    const coverPosition = calculateCoverPosition(imageUnitLessDimensions, panelUnitlessDimension);

    return {
        previewUrl: imageUrls.preview,
        printUrl: imageUrls.print,
        originalSourceUrl: imageUrls.original,
        pageNumber,
        position: {
            x: new Measurement(coverPosition.x, MeasurementUnit.MM).measurement,
            y: new Measurement(coverPosition.y, MeasurementUnit.MM).measurement,
            width: new Measurement(coverPosition.width, MeasurementUnit.MM).measurement,
            height: new Measurement(coverPosition.height, MeasurementUnit.MM).measurement,
        },
    };
};

export function isFileFusionCompatible(file: File) {
    return FUSION_COMPATIBLE_MIME_TYPES.includes(file?.type);
}

const getImageFileAsPromise = async (file: File) => {
    const url = URL.createObjectURL(file);
    return getImageAsPromise(url);
};

/**
 * Given a File, generates a `LocalImage` with a client-side ObjectURL
 * @note Adding an item to the design with this URL will leave the document in an "unsafe" state until it gets replaced with a proper URL
 */
export const buildLocalImage = async (file: File): Promise<LocalImage> => {
    // `src` returned from this function is a "blob:" ObjectURL
    const { width, height, src } = await getImageFileAsPromise(file);

    return {
        imageUrls: {
            original: src,
            print: src,
            thumb: src,
            preview: src,
        },
        imageUnitLessDimensions: {
            width,
            height,
        },
    };
};

const calculateImageSizeInInches = (height: number, width: number, productMinDpi: string) => {
    const dpi = parseInt(productMinDpi);

    const heightInInches = height / dpi;
    const widthInInches = width / dpi;

    return { height: heightInInches, width: widthInInches };
};

/**
 * @param imageURL sherbert url for the uploaded vista asset
 * @param productMinDpi minimum dpi for the product surface
 * @returns image size in inches
 */
export const getImageDimensionInInches = async (
    imageURL: string,
    authorizationHeader: string,
    productMinDpi: string
) => {
    const { id: assetId } = parseAssetUrl(imageURL);
    if (!assetId) {
        throw new Error('Could not find asset id from imageURL');
    }

    let imageInfo = await getImageInfo(assetId, authorizationHeader);

    if (imageInfo?.format === 'pdf') {
        // If the format is PDF calculate size for 1st page
        imageInfo = await getPdfInfo(assetId, authorizationHeader, 1);
    }
    if (!imageInfo || imageInfo.isError) {
        noticeError('Error file response returned by Sherbert', { method: 'getDocumentSize' });
        throw new Error('Could not get info for the imageURL');
    }

    const { height, width } = imageInfo;

    return calculateImageSizeInInches(height, width, productMinDpi);
};
