import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { LandingPage } from './components/LandingPage';

const APP_BASE_PATH = process.env.REACT_APP_ROUTER_BASENAME || '';
const cb = (
    window as Window &
        typeof globalThis & { configureNewrelic?: (accountId: string, appId: string, appKey: string) => void }
).configureNewrelic;
process.env.REACT_APP_NR_APP_ID &&
    process.env.REACT_APP_NR_ACCOUNT_ID &&
    process.env.REACT_APP_NR_LICENSE_KEY &&
    cb &&
    cb(process.env.REACT_APP_NR_ACCOUNT_ID, process.env.REACT_APP_NR_APP_ID, process.env.REACT_APP_NR_LICENSE_KEY);

export const Router = () => {
    const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<LandingPage />}></Route>), {
        basename: APP_BASE_PATH,
    });

    return <RouterProvider router={router} />;
};
