import React, { useCallback } from 'react';
import { css } from '@emotion/css';
import { className, ModalDialog, ModalDialogCloseButton, ModalDialogContent, ModalDialogNav, tokens } from '@vp/swan';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';

const borderStyle = css`
    &.swan-modal-dialog.swan-modal-dialog-panel.swan-modal-dialog-panel-bottom {
        border-radius: ${tokens.SwanSemBorderRadiusContainer} ${tokens.SwanSemBorderRadiusContainer} 0 0;
    }
`;

const navigationStyle = css`
    .swan-modal-dialog-nav {
        position: absolute;
        background: transparent;
        right: 0;
        top: 0;
    }
`;

type OmitProps = 'onRequestDismiss';
type CommonModalProps = Omit<React.ComponentProps<typeof ModalDialog>, OmitProps> & {
    children?: React.ReactNode;
    ariaLabelledby?: string;
    ariaLabel?: string;
    fullBleed?: boolean;
    positionAbsoluteNavigation?: boolean;
    contentTestId?: string;
    hasBorderRadius?: boolean;
    onlyRenderWhenOpen?: boolean | null;
    onClose?: (isOpen: boolean) => void;
};

export const CommonModal = ({
    children,
    ariaLabelledby,
    ariaLabel,
    onClose,
    fullBleed,
    positionAbsoluteNavigation,
    contentTestId,
    hasBorderRadius = true,
    onlyRenderWhenOpen = true,
    ...rest
}: CommonModalProps) => {
    const onRequestDismiss = useCallback(() => onClose && onClose(false), []);
    const { isSmall } = useScreenLayout();

    return (
        <ModalDialog onRequestDismiss={onRequestDismiss} onlyRenderWhenOpen={onlyRenderWhenOpen} {...rest}>
            <ModalDialogContent
                className={className(
                    {
                        [navigationStyle]: positionAbsoluteNavigation,
                    },
                    {
                        [borderStyle]: isSmall && hasBorderRadius,
                    }
                )}
                aria-labelledby={ariaLabelledby}
                aria-label={ariaLabel}
                fullBleed={fullBleed}
                data-testid={contentTestId}
            >
                {onClose && (
                    <ModalDialogNav>
                        <ModalDialogCloseButton
                            data-testid="modal-close-btn"
                            accessibleText={text('closeAccessibilityLabel')}
                        />
                    </ModalDialogNav>
                )}
                {children}
            </ModalDialogContent>
        </ModalDialog>
    );
};
