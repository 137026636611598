import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { Drawer, useFileMethods } from '@design-stack-vista/upload-components';
import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { observer } from 'mobx-react-lite';
import { useBackDesignTemplate } from '../../features/BackDesignTemplate';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { useUploadPanels } from '../../hooks/useUploadPanel';
import { useUploadTracking } from '../../hooks/useUploadTracking';
import { useUploadModal } from '../../providers';
import { recentImageStore } from '../../providers/Store/RecentImageStore';
import { getUsedAssets } from '../../utils';
import { text } from '../../utils/localization';

const DEFAULT_THUMBNAIL_SIZE = 95;
const SMALL_SCREEN_THUMBNAIL_PADDING = 74;

interface RecentImagesDrawerInterface {
    panelId: string;
}

export const RecentImagesDrawer = observer(({ panelId }: RecentImagesDrawerInterface) => {
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { isSmall } = useScreenLayout();
    const { onAddImageToPanel } = useUploadPanels();
    const { setTemplatePreviewsAndColors } = useBackDesignTemplate();
    const { trackRecentlyUploadedAsset } = useUploadTracking();
    const [thumbnailSize, setThumbnailSize] = useState(DEFAULT_THUMBNAIL_SIZE);
    const { showUploadModal, setValidationMessage } = useUploadModal();
    const { deleteFile } = useFileMethods();

    useEffect(() => {
        const imageThumbnailSize = isSmall
            ? (screen.width - SMALL_SCREEN_THUMBNAIL_PADDING) / 3
            : DEFAULT_THUMBNAIL_SIZE;

        setThumbnailSize(imageThumbnailSize);
    }, [isSmall, screen.width]);

    const addImage = useCallback(
        async (asset: VistaAsset, pageNumber = 1) => {
            trackRecentlyUploadedAsset(asset);
            await onAddImageToPanel(panelId, asset, pageNumber);
            const isFirstPanel = panelId === panels[0].panelProperties.id;
            if (isFirstPanel) {
                setTemplatePreviewsAndColors();
            }
        },
        [panelId, onAddImageToPanel, trackRecentlyUploadedAsset]
    );

    const onImageLoadError = useCallback(async (asset: VistaAsset) => {
        recentImageStore.setIsPreviewFailed(true);
        await deleteFile(asset);
        recentImageStore.setIsPreviewLoading(false);
        setValidationMessage(text('uploadFailed'));
    }, []);

    const usedAssets = useMemo(() => (showUploadModal ? getUsedAssets(panels) : []), [panels, showUploadModal]);
    const onLoadCompleted = useCallback(() => recentImageStore.setIsPreviewLoading(false), []);

    return (
        <Drawer
            emptyResultsElement={<div data-testid="empty-drawer"></div>}
            onClick={addImage}
            onDoubleClick={addImage}
            usedAssets={usedAssets}
            deleteOption="confirm"
            styleOptions={{ thumbnailSize, flexboxGap: 16 }}
            onImageLoadError={onImageLoadError}
            onImageLoadCompleted={onLoadCompleted}
        />
    );
});
