import { MaskType } from '@design-stack-vista/ida-framework';
import { Mask } from '../../../../config/constant';

type MessagesType = {
    [key in string]: string;
};

type MaskOffsetXPercentage = {
    [key in MaskType]: number;
};

export const messages: MessagesType = {
    bleedLabel: 'bleedLabel',
    safeLabel: 'safetyIndicator',
    trimLabel: 'trimLabel',
    bleedTooltip: 'bleedMargin',
    safeTooltip: 'safeMargin',
    trimTooltip: 'trimTooltip',
};

export const labelOffsetFromTopOfAreaBeingLabeled = 18;

export const maskOffsetXPercentage: MaskOffsetXPercentage = {
    [Mask.Bleed]: 47,
    [Mask.Safe]: 15,
    [Mask.Trim]: 75,
};
